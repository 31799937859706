.projects{
    border-radius: 50px 50px 0px 0px;
    background: linear-gradient(90deg, #0051B0 0%, #00192B 100%);
    box-shadow: 0px -4px 80px 0px rgba(162, 9, 255, 0.50);
    padding-top: 30px;
    padding-bottom: 30px;
}
.projects h2{
    display: flex;
    font-family: "Sen", sans-serif;
    font-optical-sizing: auto;
    font-weight: 850;
    font-style: normal;
    color: rgb(255, 255, 255);
    font-size: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 75px;
    max-width: 1250px;
    height: 100px;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.25);
    margin: 120px auto 50px auto;
}

.projectSection{
    opacity: 0;
    scale: 0.9;
    max-width: 1250px;
    margin: 0px auto 100px auto;
    background-color: #D9E8FF;
    box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.40);
    border-radius: 30px;
    padding: 25px 0px 0px 30px;
    position: relative;
    transition: all 0.5s;
}
.projectSection:hover{
    scale: 1.02;
}

.projectSectionMain{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.projectSectionText{
    max-width: 700px;
}

.projectSectionImg{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 550px;
}


.projectSectionInfo{
    display: flex;
    position: absolute;
    bottom: 20px;
}

.projectSectionText h3{
    font-family: "Sen", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: black;
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.projectSectionText h4{
    font-family: "Sen", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: #384CFF;
    font-size: 20px;
    margin-bottom: 0px;
}

.projectSectionText h5{
    font-family: "Sen", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: #0053b8;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.projectSectionText p{
    font-family: "Sen", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: black;
    font-size: 19px;
    
}

#design-projects{
    margin-top: 50px;
    background: linear-gradient(270deg, #1400F7 0.76%, #0A8BD4 48.97%, #00F9AE 99.24%);
}

#programming-projects{
    background:linear-gradient(270deg, #FFB800 0%, #FE4C00 100%);
}

#portfolio-project{
    background:linear-gradient(90deg, #8000FF 0%, #FA00FF 100%);
}
.mainHead:hover{
    cursor: pointer;
}
.appear{
    opacity: 1;
    scale: 1;
}

@media screen and (max-width: 1330px) {
    .projectSection{
        max-width: 750px;
        border-radius: 25px;
        padding: 25px 10px 25px 10px;
    }
    .projects h2{
        max-width: 750px;
    }
    .projectSectionInfo{
        bottom: 0px;
        position: relative;
        flex-wrap: wrap;
        margin: 0 auto !important;
        max-width: 500px;
        justify-content: center;
    }
    .projectSectionMain{
        margin-bottom: 41px !important;
    }
    .projectSectionImg{
        max-width: 700px !important;
        margin-top: 20px;
    }
    .projectSectionImg img{
        max-width: 44% !important;
        margin: 0px 10px !important;
    }
    #virtuallife img{
        max-width: 80% !important;
        margin: 0px 0px !important;
    }
    #chat-moderator img{
        max-width: 80% !important;
        margin: 0px 0px !important;
    }
    #data-structure-studies img{
        max-width: 80% !important;
        margin: 0px 0px !important;
    }
    .projectSectionText h3{
        text-align: center;
    }
    
    .projectSectionText h4{
        text-align: center;
    }
    .projectSectionText h5{
        text-align: center;
    }
    .projectSectionText p{
        text-align: center;
    }
    .projectSectionInfo p{
        text-align: center;
        margin-left: 0px !important;
    }


}
@media screen and (max-width: 820px) {
    .projects h2{
        font-size: 30px;
        border-radius: 50px;
        max-width: 500px;
        height: 80px;
    }
    .projectSection{
        max-width: 100%;
        border-radius: 0px;
        padding: 15px 13px 20px 13px;
    }
    .projectSectionText{
        max-width: auto;
    }
    .projectSectionImg{
        max-width: auto !important;
    }
    .projectSection:hover{
        scale: 1 !important;
    }


}

@media screen and (max-width: 550px) {
    .projects{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .projects h2{
        font-size: 25px;
        border-radius: 50px;
        max-width: 320px;
        height: 70px;
    }
    .projectSectionText h3{
        font-size: 24px;
    }
    
    .projectSectionText h4{
        font-size: 18px;
    }
    
    .projectSectionText p{
        font-size: 16px;
    }
    .projectSectionInfo Button{
        text-align: center;
    }
    .projectSectionInfo Button{
        font-size: 19px !important;
    }

}

@media screen and (max-width: 550px) {
    .projectSectionImg img{
        max-width: 43% !important;
    }
    .projectSection{
        max-height: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50px;
        max-width: 81%;
        background-color: linear-gradient(270deg, #1400F7 0.76%, #0A8BD4 48.97%, #00F9AE 99.24%) !important;
        background-color: #bfdefe;
        transition: all 0.3s;
    }
    .downIcons{
        height: 30px !important;
    }
    .down-icon-1 , .down-icon-2{
        width: 30px;
        border-radius: 30px;
        height: 5px;
        background-color:#0053b8;
        margin: 6px auto;
        transition: all 0.5s;
        pointer-events: none;
        display: block;
    }
    .down-icon-1{
        transform: translate(8px, 13px) rotate(135deg);
    }
    
    .down-icon-2{
        transform: translate(-11px, 2px) rotate(-135deg);
    }
    .projectSectionText h3{
        margin-bottom: 10px !important;
    }
    .iconChange .down-icon-1{
        transform: translate(1px, 10px) rotate(-45deg);
        background-color: #ff0000;
    }
    .iconChange .down-icon-2{
        transform: translate(0px, -1px) rotate(45deg);
        background-color: #ff0000;
    }
    .sectionChange{
        max-height: 2000px;
        height: 100%;
        transition: all 0.3s;
        max-width: 100%;
        border-radius: 0px;
        background-color: #D9E8FF;
    }
}